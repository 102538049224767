<script>
	import { cn } from "$lib/utils";
	let className = undefined;
	export { className as class };
</script>

<th
	class={cn(
		"h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]",
		className
	)}
	{...$$restProps}
>
	<slot />
</th>
